.PhoneInput {
  position: relative;
}

.PhoneInputCountry {
  top: 0;
  left: 10px;
  width: 24px;
  height: 42px;
  z-index: 20;
  position: absolute;
}

.PhoneInputCountrySelect {
  cursor: pointer;
  opacity: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  position: absolute;
}

.PhoneInputCountryIcon {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
}

.PhoneInputCountryIconImg {
  width: 20px;
}

.PhoneInputInput {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 42px;
  border: none;
  outline: none;
  caret-color: black;
  color: transparent;
  background-color: transparent;
  padding: 16.5px 14px 16.5px 50px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  font-family: Quicksand, sans-serif;
}

.PhoneInput .MuiFormControl-root input {
  padding: 16.5px 14px 16.5px 50px;
}

.PhoneInput--disabled .PhoneInputCountry {
  opacity: 0.5;
  pointer-events: none;
}
