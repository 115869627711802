*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

body {
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

#root {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: rgba(164, 164, 164, 0.1);
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

a {
  color: inherit;
  text-decoration: none;
}

input[type='date']:focus::-webkit-datetime-edit {
  color: inherit !important;
}
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0.4;
  cursor: pointer;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.content {
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 1860px;
}

/* @media only screen and (max-width: 700px) {
    .content {
        padding: 0;
    }
} */

.content-m {
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
}

.content-ml {
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.hideScroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.MuiLoadingButton-loadingIndicator {
  .MuiCircularProgress-circle {
    stroke: #fff;
    stroke-width: 5;
  }
}
